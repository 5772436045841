// src/personas.js
export const personas = [
  { name: "Coach Carter", tone: "Inspirational" },
  { name: "Serene Monk", tone: "Calm and Reflective" },
  { name: "Drill Sergeant", tone: "Tough Love" },
  { name: "Cheerful Friend", tone: "Friendly and Upbeat" },
  { name: "Visionary Leader", tone: "Forward-thinking" },
  { name: "Wise Elder", tone: "Nurturing Wisdom" },
  { name: "Motivational Speaker", tone: "Energetic and Persuasive" },
  { name: "Empathetic Listener", tone: "Understanding and Comforting" },
];
